import { useCallback, useEffect, useState } from 'react';
import { useLocale } from 'next-intl';
import { useSearchParams } from 'next/navigation';
import { getErrorCodeFromUri } from '@/app/utils/request/response';
import { fetchItem, getLoginUri } from '@/app/actions/server';
import { dataLayerPush } from '@/app/utils/analytics/datalayer';
import { useApiErrorContext } from '@/app/components/Provider';
import { isAuthenticated as checkIsAuthenticated } from '@/app/actions/server/authentication';
import { AnalyticEvents, CookieName } from '~/const/appConst';
import { defaultLocale, usePathname, useRouter } from '@/i18n/routing';
import { AcceptLanguage, LocaleCode } from '~/const/locale';
import { getSessionStorage, removeCookie, setCookie } from '~/utils/utils';
import { getAuthenticationToken } from '~/api/apiConnect';
import type { UserType } from '~/model/UserType';

export const defaultState: {
  user: UserType;
  isAuthenticated: boolean;
  isAuthenticating: boolean;
} = {
  user: null,
  isAuthenticated: null,
  isAuthenticating: true,
};

export function useAuthentication() {
  const locale = useLocale();
  const pathname = usePathname();
  const router = useRouter();

  const searchParams = useSearchParams();

  const { error, setError } = useApiErrorContext();

  const [user, setUser] = useState<UserType>(defaultState.user);

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    defaultState.isAuthenticated,
  );

  const [isAuthenticating, setIsAuthenticating] = useState(
    defaultState.isAuthenticating,
  );

  const handleAuthenticationCheck = useCallback(async () => {
    getAuthenticationToken(CookieName.ACCESS_TOKEN);
    getAuthenticationToken(CookieName.REFRESH_TOKEN);
    const authenticationStatus = await checkIsAuthenticated();
    setIsAuthenticated(authenticationStatus);
    setIsAuthenticating(authenticationStatus);
  }, []);

  const initUser = useCallback(async () => {
    if (!isAuthenticated)
      return setUser({});

    setIsAuthenticating(true);

    const user = await fetchItem<UserType>({
      revalidate: 0,
      pathname: 'users/me',
    });

    if (user['hydra:description']) {
      return setError(user);
    }

    if (user.favorite_locale !== AcceptLanguage[locale]) {
      setCookie(`${CookieName.COCREATION_LOCALE}=${user.favorite_locale}`);
      setCookie(`${CookieName.NEXT_LOCALE}=${LocaleCode[user.favorite_locale]}`);
      router.replace(pathname, {
        locale: LocaleCode[user.favorite_locale],
      });
    }

    setUser(user);

    dataLayerPush({
      event: AnalyticEvents.CONNECTED,
      page_name: pathname,
    });
    setIsAuthenticating(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const revokeAuthentication = () => {
    removeCookie(CookieName.ACCESS_TOKEN);
    removeCookie(CookieName.REFRESH_TOKEN);
    setUser({});
    setIsAuthenticated(false);
  };

  const redirectToLogin = async () => {
    const isLucky = getSessionStorage('isLucky');

    try {
      const newSearchParams = new URLSearchParams(searchParams);
      isLucky && newSearchParams.append('luckyMember', 'true');
      const search = newSearchParams.toString();
      const loginUriSearchParams = search ? `?${search}` : '';
      const uri = await getLoginUri(`${pathname}${loginUriSearchParams}`);
      window.location.href = uri;
    } catch (error) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    if (pathname) {
      const localeUrl = locale === defaultLocale ? '/' : `/${locale}`;

      dataLayerPush({
        page_type: pathname,
        page_name: `${localeUrl}${pathname}`,
        page_language: AcceptLanguage[locale],
        user_id: user?.id?.toString(),
        user_login_status: !!user?.id,
        user_sport: user?.sports?.map(sport => sport.label).toString(),
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, searchParams, user?.id, locale]);

  useEffect(() => {
    if (user?.id && user?.term_of_use_accepted === false) {
      dataLayerPush({
        event: AnalyticEvents.SIGN_UP,
        user_id: user?.id?.toString(),
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id]);

  useEffect(() => {
    initUser();
  }, [initUser]);

  useEffect(() => {
    handleAuthenticationCheck();
  }, [handleAuthenticationCheck]);

  useEffect(() => {
    if (
      error
      && getErrorCodeFromUri(error['@id']) === 401
      && error['hydra:description'] === 'Token signature mismatch'
    ) {
      revokeAuthentication();
    }
  }, [error]);

  return {
    user,
    isAuthenticated,
    isAuthenticating,
    redirectToLogin,
    setUser,
  };
}
