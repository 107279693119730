import type { RefObject } from 'react';
import { useEffect, useLayoutEffect } from 'react';
import type { VpIconName } from '@vtmn-play/react';
import type { MessageKeys } from 'next-intl';
import type { ProjectItemContextEnum } from '../../app/[locale]/(home)/components/ProjectsScroller/model';
import type { TranslatableEntityType } from './Interaction/Form/model';
import type { StatusFromUserType } from './Projects/model';
import { getSessionStorage, isWindow, setSessionStorage } from '~/utils/utils';
import type { LocaleType, ScrollMemorisation } from '~/model/GlobalTypes';
import type { TranslationType } from '~/model/TranslationType';
import { interactionsTypes, statusContribution } from '~/const/api';
import { ComponentTranslationStatus } from '~/const/appConst';
import type { UserContribStatusCountType } from '~/model/UserContribStatusCountType';
import type { InteractionTagsKeys } from '~/model/InteractionType';
import type { Messages } from '@/global';
import { InteractionTags } from '~/model/InteractionType';

export const sessionStorageScrollerSuffix = 'Scroll';

export const useBrowserLayoutEffect = isWindow() ? useLayoutEffect : useEffect;

export const memorizeScroll = (
  storageItem: ScrollMemorisation | ProjectItemContextEnum,
  position?: number,
) => {
  const positionToStore
    = position || position === 0
      ? position.toString()
      : window.scrollY.toString();
  // Store Scroll value
  setSessionStorage(
    `${storageItem}${sessionStorageScrollerSuffix}`,
    positionToStore,
  );
};

export const applyMemorizedScrollerPosition = (
  storageItem: ProjectItemContextEnum,
  scrollContainer: RefObject<HTMLElement>,
) => {
  // Apply memorized Scroll if exists
  const memorizedYScroll = getSessionStorage(
    `${storageItem}${sessionStorageScrollerSuffix}`,
  );

  if (memorizedYScroll && scrollContainer)
    scrollContainer.current.scrollLeft = +memorizedYScroll;
};

export const applyMemorizedWindowScroll = (storageItem: ScrollMemorisation) => {
  // Apply memorized Y Scroll if exists
  const memorizedYScroll = getSessionStorage(
    `${storageItem}${sessionStorageScrollerSuffix}`,
  );
  if (memorizedYScroll)
    window.scrollTo(0, +memorizedYScroll);
};

export const getTranslateKeyFromTag = (tag: InteractionTagsKeys): MessageKeys<
Messages,
| 'interaction.discussion'
| 'interaction.event'
| 'interaction.interview'
| 'interaction.news'
| 'interaction.product_test_onsite'
| 'interaction.product_test_remote'
| 'interaction.result'
| 'interaction.roundtable'
| 'interaction.survey_feedback'
| 'interaction.survey_quantitative'
| 'interaction.user_test'
| 'interaction.workshop'
> => {
  return Object.values<InteractionTagsKeys>(InteractionTags).includes(tag)
    ? `interaction.${tag.toLowerCase()}` as any
    : '' as any;
};

export const getTranslateKeyStatusFromType = (type: string) => {
  if (type === interactionsTypes.selection || type === interactionsTypes.survey) {
    return 'GLOBAL.FINISHED';
  } else if (
    type === interactionsTypes.thread
      || type === interactionsTypes.announcement
  ) {
    return 'GLOBAL.FINISHED_2';
  } else {
    return '';
  }
};

export const getStatusFromUserContributionStatusCount = (
  userContributionStatusCount: UserContribStatusCountType,
): StatusFromUserType[] => {
  if (!userContributionStatusCount)
    return [];

  const statuses
    = Object.entries(userContributionStatusCount).length > 0
    && Object.entries(userContributionStatusCount).reduce((a, [key, value]) => {
      if (statusContribution.initialized !== key && value > 0)
        a.push({ key, value });

      return a;
    }, []);

  return statuses && statuses.length > 0 ? statuses : [];
};

export const getStatusTranslateKeyFromContributionStatus = (
  contributionStatus: string,
): MessageKeys<
  Messages,
  | 'INTERACTION.CONTRIBUTION_STATUS.SUBMITTED'
  | 'INTERACTION.CONTRIBUTION_STATUS.ACCEPTED'
  | 'INTERACTION.CONTRIBUTION_STATUS.CONFIRMED'
  | 'INTERACTION.CONTRIBUTION_STATUS.UNCONFIRMED'
  | 'INTERACTION.CONTRIBUTION_STATUS.REFUSED'
> => {
  switch (contributionStatus) {
    case statusContribution.submitted:
      return 'INTERACTION.CONTRIBUTION_STATUS.SUBMITTED';
    case statusContribution.accepted:
      return 'INTERACTION.CONTRIBUTION_STATUS.ACCEPTED';
    case statusContribution.confirmed:
      return 'INTERACTION.CONTRIBUTION_STATUS.CONFIRMED';
    case statusContribution.unconfirmed:
      return 'INTERACTION.CONTRIBUTION_STATUS.UNCONFIRMED';
    case statusContribution.refused:
      return 'INTERACTION.CONTRIBUTION_STATUS.REFUSED';
    default:
      return '' as any;
  }
};

export const getStatusIconFromContributionStatus = (
  contributionStatus: string,
): VpIconName => {
  switch (contributionStatus) {
    case statusContribution.submitted:
    case statusContribution.accepted:
      return 'time';
    case statusContribution.confirmed:
      return 'check-circle';
    case statusContribution.unconfirmed:
    case statusContribution.refused:
      return 'close-circle';
    default:
      return 'time';
  }
};

export const getColorStatusFromContributionStatus = (
  contributionStatus: string,
): string => {
  switch (contributionStatus) {
    case statusContribution.submitted:
    case statusContribution.accepted:
      return 'info';
    case statusContribution.confirmed:
      return 'positive';
    case statusContribution.unconfirmed:
    case statusContribution.refused:
      return 'warning';
    default:
      return 'positive';
  }
};

export const getTranslationForUserLanguage = (
  translations: TranslationType,
  userLanguage: LocaleType,
  originalLocale: LocaleType,
) => {
  const status = translations?.[userLanguage]?.status;
  const enGBStatus = translations?.en_GB?.status;
  if (
    status === ComponentTranslationStatus.valid
    || status === ComponentTranslationStatus.auto
  ) {
    return translations[userLanguage];
  } else if (
    enGBStatus === ComponentTranslationStatus.valid
    || enGBStatus === ComponentTranslationStatus.auto
  ) {
    return translations.en_GB;
  }

  return translations?.[originalLocale];
};

export const retrieveTranslatedFieldsFromEntity = ({
  entityTranslated,
  entity,
  language,
}: {
  entityTranslated: boolean;
  entity: TranslatableEntityType;
  language: LocaleType;
}) => {
  return entityTranslated
    ? entity
    : getTranslationForUserLanguage(
      entity?.translations,
      language,
      entity?.original_locale,
    );
};
