'use client';

import { type PropsWithChildren, createContext, useContext } from 'react';
import { CGUDialog } from '../CGUDialog/CGUDialog';
import { BlockedAccountDialog } from '../BlockedAccountDialog/BlockedAccountDialog';
import { useAuthentication } from '@/app/hooks/useAuthentication';

const AuthenticatedContext = createContext<ReturnType<typeof useAuthentication> | undefined>(undefined);

export const useAuthenticatedContext = () => {
  const context = useContext(AuthenticatedContext);
  if (!context) {
    throw new Error(
      'useAuthenticatedContext must be called within AuthenticatedContext',
    );
  }
  return context;
};

export function AuthenticatedProvider({ children }: PropsWithChildren) {
  const value = useAuthentication();

  return (
    <AuthenticatedContext.Provider value={value}>
      {children}
      {!!value.user?.blocked_account && <BlockedAccountDialog />}
      {value.user?.term_of_use_accepted === false && (
        <CGUDialog isOpen={value.user?.term_of_use_accepted === false} />
      )}
    </AuthenticatedContext.Provider>
  );
}
