'use client';

import type { PropsWithChildren } from 'react';
import { createContext, useContext } from 'react';
import { useProjects } from '@/app/hooks/useProjects';

const ProjectsContext = createContext<ReturnType<typeof useProjects> | undefined>(undefined);

export const useProjectsContext = () => {
  const context = useContext(ProjectsContext);
  if (!context) {
    throw new Error('useProjectsContext must be called within ProjectsProvider');
  }
  return context;
};

export const ProjectsProvider = ({ children }: PropsWithChildren) => {
  const value = useProjects();

  return (
    <ProjectsContext.Provider value={value}>
      {children}
    </ProjectsContext.Provider>
  );
};
