import { getRequestConfig } from 'next-intl/server';
import type { RichTranslationValues } from 'next-intl';
import { routing } from './routing';

export const getIntlMessages = async (locale: string) => ({
  ...(await import(`@/public/static/locales/${locale}/common.json`)).default,
});

export const defaultTranslationValues: RichTranslationValues = {
  important: chunks => <strong>{chunks}</strong>,
  italic: chunks => <i>{chunks}</i>,
  paragraph: chunks => <p>{chunks}</p>,
  br: () => <br />,
};

export default getRequestConfig(async ({ requestLocale }) => {
  // This typically corresponds to the `[locale]` segment
  let locale = await requestLocale;

  // Ensure that a valid locale is used
  if (!locale || !routing.locales.includes(locale as any)) {
    locale = routing.defaultLocale;
  }

  return {
    defaultTranslationValues,
    messages: await getIntlMessages(locale),
    timeZone: 'Europe/Paris',
    now: new Date(),
  };
});
