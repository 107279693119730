import { useEffect, useState } from 'react';
import { getErrorCodeFromUri } from '../utils/request/response';
import type { UriType } from '~/model/GlobalTypes';

export interface ApiError {
  '@id': UriType;
  'hydra:description': string;
  error_description: string;
}

export function useError() {
  const [error, setError] = useState<ApiError>();

  const shouldNotRedirectOnBlocked = error?.['hydra:description'] === 'The account is blocked';
  const status = getErrorCodeFromUri(error?.['@id']);

  useEffect(() => {
    if (!shouldNotRedirectOnBlocked && status === 403) {
      window.location.reload();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return { error, setError };
}
