import { useState } from 'react';
import { ApiRoute } from '../utils/request/api-routes';
import { fetchProjectsAndTags } from '@/app/actions/server';
import { type OptionSportsType, OptionsStatusValueType } from '@/app/[locale]/projects/model';
import { useApiErrorContext, useAuthenticatedContext } from '@/app/components/Provider';
import type { Collection } from '@/app/actions/collection';
import type { ProjectType } from '~/model/ProjectType';
import type { ProjectTagType } from '~/model/ProjectTag';
import { RequestStatus, useRequestState } from '~/hooks/request/useRequestState';

export function useProjects() {
  const [projects, setProjects] = useState<Collection<ProjectType>>(null);
  const [tags, setTags] = useState<Collection<ProjectTagType>>(null);
  const [sportsFilter, setSportsFilter] = useState<OptionSportsType[]>(null);

  const { user } = useAuthenticatedContext();

  const { startRequest, handleSuccess, handleError, status } = useRequestState();
  const { setError } = useApiErrorContext();

  const isFetching = status === RequestStatus.PENDING;

  const handleSetProjects = (fetchedProjects: Collection<ProjectType>) => {
    setProjects(fetchedProjects);
  };

  const handleSetTags = (fetchedTags: Collection<ProjectTagType>) => {
    setTags(fetchedTags);
  };

  const handleSetSportsFilter = (sports: OptionSportsType[]) => {
    setSportsFilter(sports);
  };

  const fetchProjects = async (statusFilter: string = null) => {
    startRequest();

    const nextPage = projects?.['hydra:view']?.['hydra:next'];
    const nextPageUrl = nextPage && new URL(nextPage, process.env.REACT_APP_API);
    const statusFilterNextPage = nextPageUrl?.searchParams?.get('status');
    const isStatusFilterInNextPage = statusFilterNextPage === OptionsStatusValueType[statusFilter];
    const sportsFilterNextPage = nextPageUrl?.searchParams?.getAll('sports[]');

    const sportsFilterIds = sportsFilter?.map(sport =>
      sport.id,
    ).flat();

    const isEverySportsFilterInNextPage = sportsFilterNextPage?.length === sportsFilterIds?.length
      && sportsFilterIds?.every(sportId =>
        sportsFilterNextPage?.some(sportFilterId =>
          sportId === +sportFilterId,
        ),
      );

    const shouldIncrement = !!nextPage
      && (!sportsFilterIds || isEverySportsFilterInNextPage)
      && (
        !statusFilter
        || statusFilter === OptionsStatusValueType.all
        || isStatusFilterInNextPage
      );

    const pathname = shouldIncrement ? nextPage : ApiRoute.PROJECTS;

    const { projects: fetchedProjects, tags: fetchedTags } = await fetchProjectsAndTags(
      statusFilter,
      sportsFilter,
      user?.id,
      pathname,
    );

    if (fetchedProjects['hydra:description']) {
      handleError();
      return setError(fetchedProjects);
    }

    if (shouldIncrement) {
      handleSetProjects({
        ...fetchedProjects,
        'hydra:member': [...projects['hydra:member'], ...fetchedProjects['hydra:member']],
      });
      handleSetTags({
        ...fetchedTags,
        'hydra:member': [...tags['hydra:member'], ...fetchedTags['hydra:member']],
      });
    } else {
      handleSetProjects(fetchedProjects);
      handleSetTags(fetchedTags);
    }

    handleSuccess();
  };

  return {
    fetchProjects,
    handleSetProjects,
    handleSetSportsFilter,
    handleSetTags,
    isFetching,
    projects,
    sportsFilter,
    tags,
  };
}
