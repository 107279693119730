export enum ApiRoute {
  ACCEPT_CURRENT_TERMS_OF_USE = 'accept-current-terms-of-use',
  CRITERION_CHOICE_USERS = 'criterion_choice_users',
  GUEST_USERS = 'guest_users',
  INTERACTIONS = 'interactions',
  ME = 'me',
  PROJECT_TAGS = 'project_tags',
  PROJECTS = 'projects',
  REFRESH_TOKEN = 'refresh-token',
  SESSIONS = 'sessions',
  SPORTS = 'sports',
  TERMS_OF_USES = 'terms_of_uses/current',
  TOKEN = 'token',
  USERS = 'users',
  DATA = 'data',
}

type InteractionsUriTemplate =
  | `${ApiRoute.INTERACTIONS}`
  | `${ApiRoute.INTERACTIONS}/${number}`;

type ProjectsUriTemplate =
  | `${ApiRoute.PROJECTS}`
  | `${ApiRoute.PROJECTS}/${number}`;

type SessionUriTemplate =
  | `${ApiRoute.SESSIONS}`
  | `${ApiRoute.SESSIONS}/${number}`;

type UserUriTemplate =
  | `${ApiRoute.USERS}`
  | `${ApiRoute.USERS}/${ApiRoute.TOKEN | ApiRoute.REFRESH_TOKEN}`
  | `${ApiRoute.USERS}/${ApiRoute.ME | number}`
  | `${ApiRoute.USERS}/${ApiRoute.ME | number}/${ApiRoute.ACCEPT_CURRENT_TERMS_OF_USE}`
  | `${ApiRoute.USERS}/${ApiRoute.ME | number}/${ApiRoute.SPORTS}`;

  type UserDataTemplate =
    | `${ApiRoute.USERS}/${number}/${ApiRoute.DATA}`;

type UserCriteriaUriTemplate =
  | `${ApiRoute.USERS}/${number}/${ApiRoute.CRITERION_CHOICE_USERS}`
  | `${ApiRoute.GUEST_USERS}/${number}/${ApiRoute.CRITERION_CHOICE_USERS}`
  | `${ApiRoute.CRITERION_CHOICE_USERS}/${number}`
  | `${ApiRoute.CRITERION_CHOICE_USERS}`;

type TermsOfUsesUriTemplate = `${ApiRoute.TERMS_OF_USES}`;

export type UriTemplate =
  | InteractionsUriTemplate
  | ProjectsUriTemplate
  | TermsOfUsesUriTemplate
  | SessionUriTemplate
  | UserUriTemplate
  | UserDataTemplate
  | UserCriteriaUriTemplate
  | string & Record<never, never>;
