import isValid from 'date-fns/isValid';
import isSameDay from 'date-fns/isSameDay';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import subWeeks from 'date-fns/subWeeks';
import subDays from 'date-fns/subDays';
import { env } from '~/const/env';
import type { HistoryType } from '~/model/HistoryType';
import { getLanguageFromLocale } from '~/utils/utils';
import type { LocaleType } from '~/model/GlobalTypes';
import { defaultLocale } from '@/i18n/routing';

// check date is today
export const checkSameDay = (date: string) => {
  if (!date || !isValid(new Date(date)))
    return false;
  return isSameDay(new Date(date), Date.now());
};

// check date < today && > 7 days ago
export const isLastWeek = (date) => {
  if (!date || !isValid(new Date(date)))
    return false;
  const now = Date.now();
  const today = startOfDay(now);
  const day = new Date(date);
  const yesterday = endOfDay(subDays(today, 1));
  const lastWeek = startOfDay(subWeeks(today, 1));
  return isAfter(day, lastWeek) && isBefore(day, yesterday);
};

// check date < 7 days ago
export const isBeforeLastWeek = (date) => {
  if (!date || !isValid(new Date(date)))
    return false;
  const day = new Date(date);
  const today = startOfDay(Date.now());
  const lastWeek = startOfDay(subWeeks(today, 1));
  return isBefore(day, lastWeek);
};

export const updateHistoryLanguage = (
  currentLanguage: LocaleType | null,
  newLanguage: LocaleType | null,
  historyUrl: HistoryType[] = [],
): HistoryType[] => {
  return historyUrl.map(({ href, as }) => {
    const newUrl
      = newLanguage === null
        ? as.replace(`/${getLanguageFromLocale(currentLanguage)}`, '')
        : as.replace(
            `/${getLanguageFromLocale(currentLanguage)}`,
            `/${getLanguageFromLocale(newLanguage)}`,
        );
    const newAs
      = currentLanguage === null && !as.includes(`/${newLanguage}`)
        ? `/${getLanguageFromLocale(newLanguage)}${as}`
        : newUrl;
    return { href, as: newAs };
  });
};

export const newUrlFromLocaleChange = (newLocale: LocaleType, path: string) => {
  // Get website absolute URL, minus the trailing slash
  const HOST: string = env.REACT_APP_DK_CONNECT_REDIRECT_URI.slice(0, -1);
  // Get the first two characters of the locale
  const newLang = getLanguageFromLocale(newLocale) || '';
  // Build the language part of the url (/en, /it)
  const lang = newLang && newLang !== defaultLocale ? `/${newLang}` : '';
  // Build the pathname
  const prefixedPath = path !== '/' ? `${path}` : '';
  return `${HOST}${lang}${prefixedPath}`;
};
