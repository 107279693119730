'use client';

import classNames from 'classnames';
import { type PropsWithChildren, createContext } from 'react';
import { usePathname } from '@/i18n/routing';
import { useScroll } from '~/hooks/scroll/useScroll';

export const ScrollContext = createContext({ isScrollingUp: false });

export function ScrollProvider({ children }: PropsWithChildren) {
  const pathname = usePathname();
  const value = useScroll();

  const pageName
    = (pathname === '/' && '-home')
    || pathname?.split('/').join('-').split('?').shift();

  return (
    <ScrollContext.Provider value={value}>
      <div
        data-testid="layout"
        className={classNames('page-layout', {
          [`page${pageName}`]: pageName,
          'not-scrolled': value.scroll === 0,
          scrolled: value.scroll > 0,
          'is-scrolling-up': value.isScrollingUp,
        })}
      >
        {children}
      </div>
    </ScrollContext.Provider>
  );
}
